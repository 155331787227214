import React from 'react'
import styled from 'styled-components'
import translate from '../../../../modules/translate'
import webLinks from '../../../../config/data/webLinks.json'
import moment from 'moment'
import Text from '../../../../components/Text'
import Link from '../../../../components/Link'
import PrivacyManager from '../../../../vendor/Sourcepoint/components/PrivacyManager'

import Iubenda from '../../../../vendor/Iubenda'
import SPlusNeg from '../../../../images/SPlusNeg'
import ImportantLinksContainer from '../ImportantLinksContainer'
import Notification from '../../../../components/Icon/keys/iconKeys/images/Notification'

const Wrapper = styled.div`
  flex: 4;
  justify-content: center;
  align-items: center;
  @media (max-width: 599px) {
    padding-right: ${(props) => props.theme.sizes.gapEdge};
    padding-left: ${(props) => props.theme.sizes.gapEdge};
  }
`

const StyledText = styled(Text)`
  color: ${(props) => props.theme.color.textSecondary};
`

const StyledImportantLinksContainer = styled(ImportantLinksContainer)`
  display: flex;
  justify-content: space-evenly;
  padding: 0 ${(props) => props.theme.sizes.gapVertical};
  align-items: center;
  flex-direction: row;
  @media (max-width: 660px) {
    flex-direction: column;
  }
`

const StyledSpan = styled.span`
  vertical-align: middle;
`

const StyledNotificationIcon = styled(Notification)`
  margin-right: 5px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
`

const WebLinksWrapper = styled.div`
  margin-top: ${(props) => props.theme.sizes.gapVertical};
  text-align: center;
`

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.color.textSecondary};

  &:hover {
    color: ${(props) => props.theme.color.stol};
  }
`

const WebLinks = ({ ...props }) => (
  <Wrapper {...props}>
    <StyledImportantLinksContainer />
    {webLinks.secondLine.length > 0 && (
      <WebLinksWrapper>
        <StyledText>
          {webLinks.secondLine.map((link, index) => {
            return (
              <>
                <StyledLink key={index + 'weblink'} to={link.value}>
                  {link.label}&nbsp;
                </StyledLink>
                {index !== webLinks.secondLine.length - 1 ? '| ' : ''}
              </>
            )
          })}
        </StyledText>
      </WebLinksWrapper>
    )}
    <WebLinksWrapper>
      <StyledText>
        {webLinks.thirdLine.map((link, index) => {
          return (
            <>
              <StyledLink key={index + 'weblink'} to={link.value}>
                {link.label}&nbsp;
              </StyledLink>
              {index !== webLinks.thirdLine.length - 1 ? '| ' : ''}
            </>
          )
        })}
        |&nbsp;
        <Iubenda />
        &nbsp;|&nbsp;
        <PrivacyManager />
        |&nbsp;
        <StyledLink to="/p/faq">
          <SPlusNeg style={{ marginRight: '5px', verticalAlign: 'middle' }} width="20" />
          <StyledSpan>FAQ</StyledSpan>
        </StyledLink>
        &nbsp;|&nbsp;
        <StyledLink to="/p/push-nachrichten">
          <StyledNotificationIcon />
          <StyledSpan>Push-Nachrichten</StyledSpan>
        </StyledLink>
        &nbsp;|&nbsp;
        <StyledLink to="/p/netiquette">
          <StyledSpan>Netiquette</StyledSpan>
        </StyledLink>
      </StyledText>
    </WebLinksWrapper>
    <WebLinksWrapper>
      <StyledText>
        © {moment().format('YYYY')} <StyledLink to="https://www.firstavenue.it/">First Avenue GmbH</StyledLink>
      </StyledText>
    </WebLinksWrapper>
  </Wrapper>
)

export default WebLinks
